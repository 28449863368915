import { Path, PathCodec } from "@pomle/paths";
import { AssignToRoomView } from "render/views/AssignToRoom";
import { ChecklistView } from "render/views/ChecklistView";
import { CheckInCompleteView } from "render/views/CheckInCompleteView";
import { CheckOutView } from "render/views/CheckOutView";
import { StudyCounterSignView } from "render/views/StudyCounterSignView";
import { ImmediateRiskAssessmentView } from "render/views/ImmediateRiskAssessmentView";
import { JoinSessionView } from "render/views/JoinSessionView";
import { PreferredNameView } from "render/views/PreferredNameView";
import { SessionJoinCompleteView } from "render/views/SessionJoinCompleteView";
import { paths } from "./paths";
import { LocationSelectorView } from "render/views/LocationSelector";
import { SelectVisitView } from "render/views/SelectVisit";
import { SelectVisitConfirmView } from "render/views/SelectVisitConfirm";
import { AssignToSkinScanner } from "render/views/AssignToSkinScanner";
import { SelectActiveVisit } from "render/views/SelectActiveVisit";

function createStep<Codec extends PathCodec>(
  id: string,
  path: Path<Codec>,
  component: (
    params: ReturnType<Path<Codec>["decode"]>,
    options: { goTo: (url: string) => void; onBack: () => void }
  ) => React.ReactElement | null
) {
  return {
    id,
    path,
    component,
  };
}

export const steps = [
  createStep(
    "fe64c95d-dcb4-4d50-8018-1631c345ef3c",
    paths.locationSelector,
    (_, { goTo }) => {
      return <LocationSelectorView goTo={goTo} />;
    }
  ),

  createStep(
    "48316e68-66f4-11ee-a533-00155db6b9f6",
    paths.root,
    (_, { goTo }) => {
      return <JoinSessionView goTo={goTo} />;
    }
  ),

  createStep(
    "8b1584aa-5d01-41a0-a4a1-ea842c6b99ea",
    paths.selectVisit,
    (_, { goTo, onBack }) => {
      return <SelectVisitView goTo={goTo} onBack={onBack} />;
    }
  ),

  createStep(
    "4c5c519c-7659-4c94-ac3a-d0ec4c5668f3",
    paths.selectActiveVisit,
    (_, { goTo, onBack }) => {
      return <SelectActiveVisit goTo={goTo} onBack={onBack} />;
    }
  ),

  createStep(
    "4c4566e5-213c-46b4-883c-3f38e99440ab",
    paths.selectVisitConfirm,
    (params, { goTo, onBack }) => {
      return (
        <SelectVisitConfirmView
          goTo={goTo}
          onBack={onBack}
          patientId={params.patientId}
          visitId={params.visitId}
        />
      );
    }
  ),

  createStep(
    "6090e7f4-66f4-11ee-8c44-00155db6b9f6",
    paths.assignSlot,
    (params, { goTo, onBack }) => {
      return (
        <SessionJoinCompleteView
          goTo={goTo}
          onBack={onBack}
          sessionId={params.sessionId}
        />
      );
    }
  ),

  createStep(
    "6654e21c-66f4-11ee-98e4-00155db6b9f6",
    paths.awaitingConsent,
    (params, { goTo, onBack }) => {
      return (
        <ChecklistView
          goTo={goTo}
          onBack={onBack}
          patientId={params.patientId}
          visitId={params.visitId}
        />
      );
    }
  ),

  createStep(
    "738274d6-66f4-11ee-9199-00155db6b9f6",
    paths.preferredName,
    (params, { goTo, onBack }) => {
      return (
        <PreferredNameView
          goTo={goTo}
          onBack={onBack}
          patientId={params.patientId}
          visitId={params.visitId}
        />
      );
    }
  ),

  createStep(
    "36a11b4e-97g4-41ee-3030-00118db3b9f`",
    paths.immediateRiskAssessment,
    (params, { goTo, onBack }) => {
      return (
        <ImmediateRiskAssessmentView
          goTo={goTo}
          onBack={onBack}
          patientId={params.patientId}
          visitId={params.visitId}
        />
      );
    }
  ),

  createStep(
    "6ca73322-66f4-11ee-b079-00155db6b9f6",
    paths.counterSign,
    (params, { goTo, onBack }) => {
      return (
        <StudyCounterSignView
          goTo={goTo}
          onBack={onBack}
          patientId={params.patientId}
          visitId={params.visitId}
        />
      );
    }
  ),

  createStep(
    "9214fbb7-cf2d-4fa4-a5c3-805902d036da",
    paths.assignToRoom,
    (params, { goTo }) => {
      return (
        <AssignToRoomView
          goTo={goTo}
          patientId={params.patientId}
          visitId={params.visitId}
        />
      );
    }
  ),

  createStep(
    "0ca071f9-aaeb-4c39-b29e-badfb416d010",
    paths.assignToSkinScanner,
    (params, { goTo }) => {
      return (
        <AssignToSkinScanner
          goTo={goTo}
          patientId={params.patientId}
          visitId={params.visitId}
        />
      );
    }
  ),

  createStep(
    "96a46898-66f4-11ee-8bdd-00155db6b9f6",
    paths.checkInSuccess,
    (params, { goTo }) => {
      return (
        <CheckInCompleteView
          goTo={goTo}
          patientId={params.patientId}
          visitId={params.visitId}
        />
      );
    }
  ),

  createStep(
    "a3290b96-66f4-11ee-ab4b-00155db6b9f6",
    paths.checkOut,
    (_, { goTo }) => {
      return <CheckOutView goTo={goTo} />;
    }
  ),
];

export function findStep(pathname: string) {
  return steps.find((step) => {
    // Look for exact match
    return step.path.match(pathname) === 0;
  });
}
