import { useMemo } from "react";
import { useConfig } from "render/context/ConfigContext";
import {
  type AuthMethod,
  createPatientDeviceURL,
  createSessionToken,
} from "../token";

export function useQRCodeURL({
  authMethod,
  sessionId,
}: {
  authMethod: AuthMethod;
  sessionId: string | undefined;
}) {
  const config = useConfig();

  const token = useMemo(() => {
    if (!sessionId) {
      return;
    }

    return createSessionToken(sessionId);
  }, [sessionId]);

  const url = useMemo(() => {
    if (!token) {
      return;
    }
    const url = config.apiBaseUrl.includes("preprod")
      ? "https://patient.preprod.cur8.co/"
      : config.appConfig.patientAppBaseUrl;
    return createPatientDeviceURL(token, url, authMethod);
  }, [token, config, authMethod]);

  return url;
}
