import { fromAPI } from "@cur8/rich-entity";
import { skipToken, useQuery } from "@tanstack/react-query";
import { APIClient } from "lib/api/client";
import { useAPIClient } from "render/context/APIContext";
import { ExtractBody, ExtractKey, QueryOptions } from "typings/query";

const queryKey = (patientId: string | undefined) => [
  "visit-summaries",
  patientId,
];
const queryFn = (apiClient: APIClient, patientId: string) => async () => {
  return await apiClient.visitSummary
    .querySummary({ patientId }, {})
    .result.then((result) => result.items.map(fromAPI.toVisitSummary));
};

type Body = ExtractBody<typeof queryFn>;
type Key = ExtractKey<typeof queryKey>;

export function useVisitSummariesQuery<T = Body>({
  patientId,
  options = {},
}: {
  patientId: string | undefined;
  options?: QueryOptions<Body, Key, T>;
}) {
  const apiClient = useAPIClient();

  return useQuery({
    queryKey: queryKey(patientId),
    queryFn: patientId ? queryFn(apiClient, patientId) : skipToken,
    ...options,
  });
}
