import { Patient } from "@cur8/rich-entity";
import { useMutation } from "@tanstack/react-query";
import { useAPIClient } from "render/context/APIContext";
import { useReporting } from "render/hooks/useReporting";

export function usePreferredNameMutation() {
  const apiClient = useAPIClient();
  const { logError } = useReporting();

  return useMutation({
    mutationFn({
      patientId,
      patientNameObject,
      preferredName,
    }: {
      patientId: string;
      patientNameObject: Patient["name"];
      preferredName: string;
    }) {
      const name = patientNameObject ?? {};
      name.displayName = preferredName;

      return apiClient.patient.updatePatient(patientId, {
        name,
      }).result;
    },
    onError: (error) => logError(error),
  });
}
