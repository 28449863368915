import { useCallback, useMemo, useState } from "react";
import { paths } from "render/routes/paths";
import { ActionsFooter } from "render/ui/layout/ActionsFooter";
import { InformationLayout } from "render/ui/layout/InformationLayout/InformationLayout";
import { PageFrameContent } from "render/ui/layout/PageFrameContent/PageFrameContent";
import { Input } from "render/ui/presentation/Input";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { Trans } from "./trans";
import { Typography } from "@cur8/maneki";
import styles from "./styles.module.sass";
import { CheckInParams } from "lib/types/checkInParameters";
import { usePreferredNameMutation } from "hooks/mutations/usePreferredNameMutation";
import { usePatientQuery } from "hooks/queries/usePatientQuery";

type PreferredNameViewProps = {
  goTo(url: string): void;
  onBack(): void;
} & CheckInParams;

export function PreferredNameView({
  goTo,
  onBack,
  visitId,
  patientId,
}: PreferredNameViewProps) {
  const preferredNameMutation = usePreferredNameMutation();

  const { data: patient } = usePatientQuery({ patientId });

  const currentName = useMemo(() => {
    return patient?.name?.displayName ?? "";
  }, [patient]);

  const [preferredName, setPreferredName] = useState(currentName);

  const handleContinue = useCallback(async () => {
    if (!patient) {
      return;
    }

    if (preferredName !== currentName) {
      preferredNameMutation.mutate({
        patientId: patientId,
        patientNameObject: patient.name,
        preferredName,
      });
    }

    const url = paths.immediateRiskAssessment.url({ patientId, visitId });

    goTo(url);
  }, [
    currentName,
    goTo,
    patient,
    patientId,
    preferredName,
    preferredNameMutation,
    visitId,
  ]);

  const canContinue = preferredName.trim().length > 0;

  return (
    <PageFrameContent>
      <InformationLayout
        content={
          <section className={styles.layout}>
            <Typography variant="display-s">
              <Trans.Title />
            </Typography>
            <Input
              label="Preferred Name"
              id="Preferred Name"
              error={
                preferredName.trim().length === 0
                  ? "Please enter member's preferred name"
                  : undefined
              }
              type="text"
              value={preferredName}
              onChange={(event) => {
                setPreferredName(event.target.value);
              }}
              onClear={() => setPreferredName("")}
            />
          </section>
        }
        buttons={
          <ActionsFooter
            left={
              <ActionButton
                variant="secondary"
                direction="backward-centered"
                onClick={onBack}
                hideIcon
              >
                Back
              </ActionButton>
            }
            right={
              <ActionButton
                disabled={!canContinue}
                onClick={handleContinue}
                variant="suggestion"
                busy={preferredNameMutation.isPending}
              >
                Continue
              </ActionButton>
            }
          />
        }
      />
    </PageFrameContent>
  );
}
