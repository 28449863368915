import { useLatestConsentsQuery } from "hooks/queries/usePatientConsentsQuery";
import { ImmediateRiskAssessment } from "lib/types/immediate-risk-assessment";
import { useCallback, useMemo, useState } from "react";
import { useAsyncHandle } from "render/hooks/useAsyncHandle";
import { paths } from "render/routes/paths";
import { ActionsFooter } from "render/ui/layout/ActionsFooter/ActionsFooter";
import { PageFrameContent } from "render/ui/layout/PageFrameContent/PageFrameContent";
import { BinaryRadio } from "render/ui/presentation/BinaryRadio";
import { Group, getGroupHighlight } from "render/ui/presentation/Group";
import { ActionButton } from "render/ui/trigger/ActionButton/ActionButton";
import styles from "./styles.module.sass";
import { Trans } from "./trans";
import { useImmediateRiskMutation } from "hooks/mutations/useImmediateRiskMutation";
import { Typography } from "@cur8/maneki";
import { InformationLayout } from "render/ui/layout/InformationLayout";
import { CheckInParams } from "lib/types/checkInParameters";
import { useVisitQuery } from "hooks/queries/useVisitQuery";
import { APITypesV2 } from "@cur8/api-client";

type ImmediateRiskAssessmentProps = {
  goTo(url: string): void;
  onBack(): void;
} & CheckInParams;

const DEFAULT_ERROR_MESSAGE = "Please answer this question";

type Errors = {
  [key in keyof ImmediateRiskAssessment]: string | undefined;
};

const validate = (risk: ImmediateRiskAssessment) => {
  const newErrors = {} as Errors;

  if (risk.acuteSymptoms === undefined) {
    newErrors.acuteSymptoms = DEFAULT_ERROR_MESSAGE;
  }

  if (risk.eyeSurgery === undefined) {
    newErrors.eyeSurgery = DEFAULT_ERROR_MESSAGE;
  }

  if (risk.medicationUsageProneToBleeding === undefined) {
    newErrors.medicationUsageProneToBleeding = DEFAULT_ERROR_MESSAGE;
  }

  return newErrors;
};

const INITIAL_RISK_ASSESSMENT: ImmediateRiskAssessment = {
  acuteSymptoms: undefined,
  eyeSurgery: undefined,
  medicationUsageProneToBleeding: undefined,
};

export function ImmediateRiskAssessmentView({
  goTo,
  onBack,
  patientId,
  visitId,
}: ImmediateRiskAssessmentProps) {
  const [risk, setRisk] = useState<ImmediateRiskAssessment>(
    INITIAL_RISK_ASSESSMENT
  );
  const immediateRiskMutation = useImmediateRiskMutation();
  const [errors, setErrors] = useState<Errors>();

  const { data: latestConsents } = useLatestConsentsQuery({
    patientId: patientId,
  });

  const { data: visit } = useVisitQuery({
    patientId: patientId,
    visitId: visitId,
  });

  const hasStudiesOnVisit = useMemo(() => {
    return visit?.consents?.some(
      (consent) =>
        consent.consentType === APITypesV2.ConsentType.Study &&
        consent.displayStages.includes(
          APITypesV2.ConsentDisplayStage.DuringVisit
        )
    );
  }, [visit?.consents]);

  const hasCounterSignRequiredConsents = useMemo(() => {
    return latestConsents?.some(
      (consent) => consent.relevantSignature.counterSignatureRequired === true
    );
  }, [latestConsents]);

  const shouldGoToStudyCounterSignPage = useMemo(() => {
    return hasStudiesOnVisit && hasCounterSignRequiredConsents;
  }, [hasCounterSignRequiredConsents, hasStudiesOnVisit]);

  const url = useMemo(() => {
    return shouldGoToStudyCounterSignPage
      ? paths.counterSign.url({ patientId, visitId })
      : paths.assignToRoom.url({ patientId, visitId });
  }, [patientId, shouldGoToStudyCounterSignPage, visitId]);

  const handleContinue = useAsyncHandle(
    useCallback(async () => {
      if (!risk) {
        return;
      }

      const errors = validate(risk);
      const hasErrors = Object.keys(errors).length > 0;

      if (hasErrors) {
        setErrors(errors);
        return;
      }

      immediateRiskMutation.mutate({
        patientId: patientId,
        visitId: visitId,
        assessment: risk,
      });

      goTo(url);
    }, [goTo, immediateRiskMutation, patientId, risk, url, visitId])
  );

  return (
    <PageFrameContent>
      <InformationLayout
        content={
          <section className={styles.layout}>
            <Typography variant="display-s">
              <Trans.Title />
            </Typography>

            <div className={styles.questions}>
              <Group
                highlight={getGroupHighlight({
                  value: risk?.acuteSymptoms,
                  hasError: Boolean(errors?.acuteSymptoms),
                })}
              >
                <BinaryRadio
                  name="immediate_risk_assessment.accuteSymptoms"
                  label="Does the member have acute symptoms today?"
                  value={risk?.acuteSymptoms}
                  error={errors?.acuteSymptoms}
                  onChange={(value) => {
                    setRisk((prev) => ({ ...prev, acuteSymptoms: value }));
                    if (errors) {
                      delete errors["acuteSymptoms"];
                    }
                  }}
                />
              </Group>
              <Group
                highlight={getGroupHighlight({
                  value: risk?.eyeSurgery,
                  hasError: Boolean(errors?.eyeSurgery),
                })}
              >
                <BinaryRadio
                  name="immediate_risk_assessment.eyeSurgery"
                  label="Eye surgery performed in the last week?"
                  value={risk?.eyeSurgery}
                  error={errors?.eyeSurgery}
                  onChange={(value) => {
                    setRisk((prev) => ({ ...prev, eyeSurgery: value }));
                    if (errors) {
                      delete errors["eyeSurgery"];
                    }
                  }}
                />
              </Group>

              <Group
                highlight={getGroupHighlight({
                  value: risk?.medicationUsageProneToBleeding,
                  hasError: Boolean(errors?.medicationUsageProneToBleeding),
                })}
              >
                <BinaryRadio
                  name="immediate_risk_assessment.medicationUsageProneToBleeding"
                  label="Medication usage predisposing to bleeding? "
                  value={risk?.medicationUsageProneToBleeding}
                  error={errors?.medicationUsageProneToBleeding}
                  onChange={(value) => {
                    setRisk((prev) => ({
                      ...prev,
                      medicationUsageProneToBleeding: value,
                    }));
                    if (errors) {
                      delete errors["medicationUsageProneToBleeding"];
                    }
                  }}
                />
              </Group>
            </div>
          </section>
        }
        buttons={
          <ActionsFooter
            left={
              <ActionButton
                variant="secondary"
                direction="backward-centered"
                onClick={onBack}
                hideIcon
              >
                Back
              </ActionButton>
            }
            right={
              <ActionButton
                variant="suggestion"
                busy={handleContinue.busy}
                onClick={handleContinue.run}
              >
                Continue
              </ActionButton>
            }
          />
        }
      />
    </PageFrameContent>
  );
}
