import { Typography } from "@cur8/maneki";
import styles from "./styles.module.sass";
import { Radio, RadioProps } from "react-aria-components";

export function LoginOption({
  children,
  ...props
}: { children: React.ReactNode } & RadioProps) {
  return (
    <Radio value={props.value} className={styles.LoginOption}>
      <div className={styles.checkboxIcon} />
      <div className={styles.label}>
        <Typography variant="label-m">{children}</Typography>
      </div>
    </Radio>
  );
}
