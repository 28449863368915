import { ReactComponent as CrossIcon } from "render/assets/icons/24x24/24x24_close.svg";
import { ReactNode, forwardRef } from "react";
import styles from "./styles.module.sass";

interface InputProps {
  autoFocus?: boolean;
  required?: boolean;
  label: string | JSX.Element;
  name?: string;
  id: string;
  error?: ReactNode;
  value: string | number;
  onClear: () => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  cta?: {
    label: string | JSX.Element;
    onClick: () => void;
  };
  autocomplete?: React.InputHTMLAttributes<HTMLInputElement>["autoComplete"];
  disabled?: boolean;
  pattern?: string;
  inputmode?: React.InputHTMLAttributes<HTMLInputElement>["inputMode"];
  type?: React.InputHTMLAttributes<HTMLInputElement>["type"];
  hideClearButton?: boolean;
}

export const Input = forwardRef(
  (
    {
      autoFocus,
      label,
      error,
      onClear,
      value,
      onChange,
      cta,
      autocomplete,
      name,
      id,
      disabled,
      required = false,
      inputmode,
      pattern,
      type,
      hideClearButton = false,
    }: InputProps,
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    const hasValue = value != null && value !== "";

    return (
      <div className={styles.inputContainer}>
        {error && <div className={styles.error}>{error}</div>}
        {cta && hasValue && (
          <button onClick={cta.onClick} className={styles.cta}>
            {cta.label}
          </button>
        )}
        {!hideClearButton && hasValue && (
          <CrossIcon onClick={onClear} className={styles.clearButton} />
        )}
        <input
          autoFocus={autoFocus}
          ref={ref}
          type={type}
          inputMode={inputmode}
          pattern={pattern}
          required={required}
          disabled={disabled}
          name={name}
          id={id}
          onChange={onChange}
          value={value}
          placeholder={" "}
          autoComplete={autocomplete}
          className={styles.input}
          onKeyUp={async (e) => {
            if (e.key === "Enter") {
              await cta?.onClick();
            }
          }}
        />
        <label className={styles.labelTop} htmlFor={id}>
          {label}
        </label>
        <label className={styles.label} htmlFor={id}>
          {label}
        </label>
      </div>
    );
  }
);
