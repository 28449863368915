import { useCounterSignConsentMutation } from "hooks/mutations/useCounterSignConsentMutation";
import { useLatestConsentsQuery } from "hooks/queries/usePatientConsentsQuery";
import { useCallback } from "react";
import { useReporting } from "render/hooks/useReporting";
import { paths } from "render/routes/paths";
import { ActionsFooter } from "render/ui/layout/ActionsFooter";
import { InformationLayout } from "render/ui/layout/InformationLayout";
import { PageFrameContent } from "render/ui/layout/PageFrameContent";
import { ChecklistItem } from "render/ui/presentation/ChecklistItem";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { BailView } from "render/views/BailView";
import styles from "./styles.module.sass";
import { Typography } from "@cur8/maneki";
import { List } from "render/ui/presentation/List";
import { CheckInParams } from "lib/types/checkInParameters";

type CounterSignViewProps = {
  goTo(url: string): void;
  onBack(): void;
} & CheckInParams;

export function StudyCounterSignView({
  goTo,
  onBack,
  patientId,
  visitId,
}: CounterSignViewProps) {
  const { logError } = useReporting();

  const counterSignConsent = useCounterSignConsentMutation();

  const { data: consents } = useLatestConsentsQuery({
    patientId: patientId,
  });

  const goNext = useCallback(() => {
    const url = paths.assignToRoom.url({ patientId, visitId });
    goTo(url);
  }, [goTo, patientId, visitId]);

  const handleCounterSignAndFinishCheckIn = async () => {
    if (!consents) return;

    try {
      await Promise.all(
        consents
          .filter((c) => c.relevantSignature.counterSignatureRequired)
          .filter(
            (c) =>
              c.relevantSignature.revoked == false &&
              c.relevantSignature.expired == false &&
              c.relevantSignature.optedOut == false
          )
          .map((consent) =>
            counterSignConsent.mutateAsync({
              consent,
              patientId: patientId,
            })
          )
      );
      goNext();
    } catch (error) {
      logError(error);
    }
  };

  if (!consents) {
    return <BailView title="No Consents Found" />;
  }

  return (
    <PageFrameContent>
      <InformationLayout
        content={
          <div className={styles.layout}>
            <Typography variant="display-s">
              By continuing you confirm and sign that the member...
            </Typography>
            <List>
              <ChecklistItem checked>
                Is able to understand the study information
              </ChecklistItem>
              <List.Divider />
              <ChecklistItem checked>
                Is able to provide an informed consent
              </ChecklistItem>
              <List.Divider />
              <ChecklistItem checked>
                Meets all clinical study requirements
              </ChecklistItem>
              <List.Divider />
              <ChecklistItem checked>
                Has no unanswered questions about the studies
              </ChecklistItem>
            </List>
            <Typography variant="body-m" color="subtle">
              If the member doesn’t meet the requirements, please cancel and
              refund the appointment.
            </Typography>
          </div>
        }
        buttons={
          <ActionsFooter
            left={
              <ActionButton
                variant="secondary"
                direction="backward-centered"
                onClick={onBack}
                hideIcon
              >
                Back
              </ActionButton>
            }
            right={
              <ActionButton
                variant="suggestion"
                busy={counterSignConsent.isPending}
                onClick={handleCounterSignAndFinishCheckIn}
              >
                Continue
              </ActionButton>
            }
          />
        }
      />
    </PageFrameContent>
  );
}
