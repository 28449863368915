import { APITypesV1 } from "@cur8/api-client";
import { fromAPI, ImmutableScan, Visit } from "@cur8/rich-entity";
import { useCallback, useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { Poll } from "render/hooks/effects";
import { useReporting } from "render/hooks/useReporting";
import { paths } from "render/routes/paths";
import { InformationLayout } from "render/ui/layout/InformationLayout/InformationLayout";
import { PageFrameContent } from "render/ui/layout/PageFrameContent/PageFrameContent";
import { List } from "render/ui/presentation/List";
import { Typography } from "@cur8/maneki";
import { ReactComponent as Checkmark } from "render/assets/icons/16x16/16x16_checkmark.svg";
import styles from "./styles.module.sass";
import { Link } from "render/ui/trigger/Link";
import { CheckInParams } from "lib/types/checkInParameters";
import { useVisitQuery } from "hooks/queries/useVisitQuery";

// TODO: move to using "immutableScansPerformed" on the visit rather than querying scans
function useWaitForScan(type: string, visit?: Visit) {
  const api = useAPIClient();
  const { handleError } = useReporting();

  const [scan, setScan] = useState<ImmutableScan>();

  useEffect(() => {
    if (!visit?.checkinStartTimestamp) {
      return;
    }

    const patientId = visit.patientId;
    const startTime = visit.checkinStartTimestamp;

    async function queryScans(type: string) {
      const scans = await api.scan
        .queryScans({
          patientId,
          scanType: type,
          order: APITypesV1.SortOrder.Desc,
          pageSize: 1,
        })
        .result.then((result) => result.items.map(fromAPI.toImmutableScan));

      return scans.find((scan) => {
        return scan.timestamp > startTime;
      });
    }

    return Poll.run(async () => {
      const scan = await queryScans(type).catch(handleError);
      if (scan) {
        setScan(scan);
        return Poll.STOP;
      }
    }, 5000);
  }, [api, type, visit, handleError]);

  return scan;
}

type CheckInCompleteViewProps = {
  goTo(url: string): void;
} & CheckInParams;

export function CheckInCompleteView({
  goTo,
  patientId,
  visitId,
}: CheckInCompleteViewProps) {
  const { data: visit } = useVisitQuery({ patientId, visitId });
  const skinScan = useWaitForScan("skin", visit);
  const cardioScan = useWaitForScan("cardio", visit);
  const tissueScan = useWaitForScan("tci", visit);

  const goToNext = useCallback(() => {
    const url = paths.checkOut.url({ patientId, visitId });
    goTo(url);
  }, [goTo, patientId, visitId]);

  useEffect(() => {
    if (!skinScan || !cardioScan || !tissueScan) {
      return;
    }
    goToNext();
  }, [skinScan, cardioScan, tissueScan, goToNext]);

  return (
    <PageFrameContent>
      <InformationLayout
        content={
          <section className={styles.layout}>
            <Typography variant="display-s">Scan progress</Typography>
            <List>
              <List.Item>
                <div className={styles.scanItem}>
                  <div className={styles.itemTitle}>
                    <Typography variant="title-s">Skin Scan</Typography>
                    <Link
                      onPress={() =>
                        goTo(
                          paths.assignToSkinScanner.url({
                            patientId,
                            visitId,
                          })
                        )
                      }
                    >
                      Re-assign member
                    </Link>
                  </div>
                  {skinScan != null ? (
                    <StatusBadge
                      checked={!!skinScan}
                      checkedStatusLabel="Completed"
                    />
                  ) : undefined}
                </div>
              </List.Item>
              <List.Divider />
              <List.Item>
                <div className={styles.scanItem}>
                  <div className={styles.itemTitle}>
                    <Typography variant="title-s">Cardio Scan</Typography>
                  </div>
                  {cardioScan != null ? (
                    <StatusBadge
                      checked={!!cardioScan}
                      checkedStatusLabel="Completed"
                    />
                  ) : undefined}
                </div>
              </List.Item>
              <List.Divider />
              <List.Item>
                <div className={styles.scanItem}>
                  <div className={styles.itemTitle}>
                    <Typography variant="title-s">Tissue Scan</Typography>
                  </div>
                  {tissueScan != null ? (
                    <StatusBadge
                      checked={!!tissueScan}
                      checkedStatusLabel="Completed"
                    />
                  ) : undefined}
                </div>
              </List.Item>
            </List>
          </section>
        }
      />
    </PageFrameContent>
  );
}

function StatusBadge({ checked, checkedStatusLabel }: StatusBadgeProps) {
  return (
    <div className={styles.status} data-checked={checked}>
      {checked && <Checkmark />}
      <Typography variant="eyebrow-m" as="span">
        {checkedStatusLabel}
      </Typography>
    </div>
  );
}

type StatusBadgeProps = {
  checked: boolean | undefined;
  checkedStatusLabel: string;
};
