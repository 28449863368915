import { APITypesV1 } from "@cur8/api-client";
import { fromAPI } from "@cur8/rich-entity";
import { skipToken, useQuery } from "@tanstack/react-query";
import { APIClient } from "lib/api/client";
import { DateTime } from "luxon";
import { useAPIClient } from "render/context/APIContext";

export function useVisitCandidatesQuery({
  patientId,
  origin,
}: {
  patientId: string | undefined;
  origin: DateTime;
}) {
  const apiClient = useAPIClient();

  return useQuery({
    queryKey: queryKey(patientId),
    queryFn: patientId ? queryFn(apiClient, patientId, origin) : skipToken,
  });
}

function queryFn(apiClient: APIClient, patientId: string, origin: DateTime) {
  return async function () {
    const range = {
      start: origin.startOf("day"),
      end: origin.endOf("day"),
    };

    const startTime = range.start.toISO();
    const endTime = range.end.toISO();
    if (!startTime || !endTime) {
      throw new Error("Invalid Dates");
    }

    const visitDTO = await apiClient.visit.getPatientVisits(
      {
        patientId,
      },
      {
        pageSize: 20,
        startDateTimeOffsetRange: {
          start: startTime,
          end: endTime,
        },
      }
    ).result;

    const filteredVisits = visitDTO.items.filter(
      (visit) => !isCancelled(visit)
    );

    return filteredVisits.map(fromAPI.toVisit);
  };
}

function queryKey(patientId: string | undefined) {
  return ["visitCandidates", patientId] as const;
}

function isCancelled(visit: APITypesV1.Visit) {
  return visit.status === APITypesV1.VisitStatus.Cancelled;
}
