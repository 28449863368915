import { APIFetchError, APITypesV1 } from "@cur8/api-client";
import { useCallback, useEffect, useMemo, useState } from "react";
import QRCode from "react-qr-code";
import { useReporting } from "render/hooks/useReporting";
import { paths } from "render/routes/paths";
import { PageFrameContent } from "render/ui/layout/PageFrameContent/PageFrameContent";
import { BailView } from "../BailView/BailView";
import { ReactComponent as QRCodeIcon } from "./assets/show-qrcode.svg";
import { useQRCodeURL } from "./hooks/useQRCodeURL";
import styles from "./styles.module.sass";
import { Typography } from "@cur8/maneki";
import {
  extendLocationCookie,
  getAndParseLocationCookie,
} from "lib/nekoCookies";
import type { AuthMethod } from "render/views/JoinSessionView/token";
import { LoginOption } from "render/views/JoinSessionView/components/LoginOption";
import { Link } from "render/ui/trigger/Link";
import { useCreateSessionMutation } from "hooks/mutations/useCreateSessionMutation";
import { useFetchSessionQuery } from "hooks/queries/useFetchSessionQuery";
import { RadioGroup } from "render/ui/presentation/RadioGroup";

interface JoinSessionViewProps {
  goTo(url: string): void;
}

const authMethods: { id: AuthMethod; description: string }[] = [
  { id: "bankid", description: "BankID" },
  { id: "phoneauth", description: "Phone authentication" },
];

export function JoinSessionView({ goTo }: JoinSessionViewProps) {
  const [hasSessionGone, setHasSessionGone] = useState<boolean | undefined>(
    undefined
  );
  const [sessionEstablished, setSessionEstablished] = useState(false);

  const { logError } = useReporting();

  const parsedLocation = getAndParseLocationCookie();

  const [authMethod, setAuthMethod] = useState<AuthMethod>(
    parsedLocation?.siteId.startsWith("SE-") ? "bankid" : "phoneauth"
  );

  useEffect(() => {
    if (parsedLocation == null) {
      goTo(paths.locationSelector.url({}));
      return;
    }

    extendLocationCookie(parsedLocation);
  }, [goTo, parsedLocation]);

  const createSessionMutation = useCreateSessionMutation();

  const fetchSessionQuery = useFetchSessionQuery({
    sessionId: createSessionMutation.data?.sessionId,
    options: {
      enabled:
        Boolean(createSessionMutation.data?.sessionId) &&
        !hasSessionGone &&
        !sessionEstablished,
      refetchInterval: POLL_BACKOFF_TIME,
    },
  });

  const goToNext = useCallback(() => {
    if (createSessionMutation.data?.sessionId == null) {
      return;
    }

    const url = paths.assignSlot.url({
      sessionId: createSessionMutation.data?.sessionId,
    });
    goTo(url);
  }, [createSessionMutation.data?.sessionId, goTo]);

  useEffect(() => {
    if (
      fetchSessionQuery.data?.sessionState ===
      APITypesV1.SessionState.Established
    ) {
      return setSessionEstablished(true);
    }
    setSessionEstablished(false);
  }, [fetchSessionQuery.data?.sessionState]);

  useEffect(() => {
    if (!sessionEstablished) {
      return;
    }
    goToNext();
  }, [goToNext, sessionEstablished]);

  const startCheckIn = useCallback(async () => {
    // hide QR code after set time
    setTimeout(() => createSessionMutation.reset(), QR_CODE_TIMEOUT);

    const seed = createSeed();

    createSessionMutation.mutate(seed, {
      onError: (error) => logError(error),
    });
  }, [createSessionMutation, logError]);

  const patientDeviceURL = useQRCodeURL({
    authMethod,
    sessionId: createSessionMutation.data?.sessionId,
  });

  const hideCode = useMemo(() => {
    if (sessionEstablished) {
      return true;
    }
    if (createSessionMutation.data?.sessionId) {
      return false;
    }
    return true;
  }, [createSessionMutation.data?.sessionId, sessionEstablished]);

  if (
    fetchSessionQuery.error instanceof APIFetchError &&
    fetchSessionQuery.error.response.status === 404
  ) {
    setHasSessionGone(true);

    return <BailView message="Session gone" />;
  }

  return (
    <PageFrameContent>
      <div className={styles.JoinSessionView}>
        <section className={styles.intro}>
          <h1 className={styles.location}>
            {parsedLocation && parsedLocation.name}
          </h1>
          <div className={styles.paddingContainer}>
            <h2 className={styles.title}>Neko Health</h2>
            <Typography variant="title-s" color="subtle">
              Please point your phone camera at the QR code to check-in.
            </Typography>
          </div>
        </section>

        <section>
          <div className={styles.linksLayout}>
            <div className={styles.qrCode} data-url={patientDeviceURL}>
              <button
                className={styles.blur}
                data-visible={!hideCode}
                onClick={startCheckIn}
              >
                <div className={styles.showcta}>
                  <QRCodeIcon height={40} width={40} />
                  <Typography variant="label-m" as="span">
                    Show code
                  </Typography>
                </div>
              </button>
              <div className={styles.frame}>
                <QRCode
                  value={patientDeviceURL ?? FAKE_QR_ENTROPY}
                  size={228}
                />
              </div>
              <form
                className={styles.form}
                hidden={hideCode}
                onChange={(event) => {
                  const { elements } = event.currentTarget;
                  const el = elements.namedItem("authmethod") as RadioNodeList;
                  setAuthMethod(el.value as AuthMethod);
                }}
              >
                <RadioGroup
                  aria-label="Select authentication method"
                  onChange={(value) => {
                    setAuthMethod(value as AuthMethod);
                  }}
                  value={authMethod}
                >
                  {authMethods.map(({ id, description }) => (
                    <LoginOption key={id} value={id}>
                      {description}
                    </LoginOption>
                  ))}
                </RadioGroup>
              </form>
            </div>
            <div className={styles.links}>
              <Link
                onPress={() => {
                  // setTimeout to hide jank
                  setTimeout(() => createSessionMutation.reset(), 1000);
                  goTo(paths.selectVisit.url({}));
                }}
              >
                Manual check-in
              </Link>
              <Link
                onPress={() => {
                  // setTimeout to hide jank
                  setTimeout(() => createSessionMutation.reset(), 1000);
                  goTo(paths.selectActiveVisit.url({}));
                }}
              >
                Active sessions
              </Link>
            </div>
          </div>
        </section>
      </div>
    </PageFrameContent>
  );
}

const FAKE_QR_ENTROPY = "0129841902481=24g9182418=4gk190gk190gj8m";

const POLL_BACKOFF_TIME = 2000;

const QR_CODE_TIMEOUT = 5 * 60 * 1000;

function createSeed() {
  return (Math.random() * Number.MAX_SAFE_INTEGER).toFixed();
}
