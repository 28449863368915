import { APITypesV2 } from "@cur8/api-client";

export function getIsSigned(signature: APITypesV2.ConsentSummary | undefined) {
  if (signature) {
    if (signature.optedOut) {
      return true;
    }

    if (!signature.expired && !signature.revoked) {
      return true;
    }
  }

  return false;
}
