import { ActionsFooter } from "render/ui/layout/ActionsFooter";
import { InformationLayout } from "render/ui/layout/InformationLayout";
import { PageFrameContent } from "render/ui/layout/PageFrameContent";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { BailView } from "render/views/BailView";
import { Typography } from "@cur8/maneki";
import { List } from "render/ui/presentation/List";
import styles from "./styles.module.sass";
import { SpinningLoader } from "render/ui/presentation/SpinningLoader";
import { ReactComponent as Checkmark } from "render/assets/icons/96x96_check.svg";
import { paths } from "render/routes/paths";
import { useSkinScannerStatusQuery } from "hooks/queries/useSkinScannerStatus";
import { APITypesV1 } from "@cur8/api-client";
import { useAssignSkinScannerMutation } from "hooks/mutations/useAssignSkinScannerMutation";
import { useMemo } from "react";
import { Link } from "render/ui/trigger/Link";
import { CheckInParams } from "lib/types/checkInParameters";
import { usePatientQuery } from "hooks/queries/usePatientQuery";

export function AssignToSkinScanner({
  goTo,
  patientId,
  visitId,
}: AssignToSkinScannerProps) {
  const { data: patient } = usePatientQuery({ patientId });
  const assignSkinScannerMutation = useAssignSkinScannerMutation();
  const skinScannerStatusQuery = useSkinScannerStatusQuery({
    patientId: patientId,
    visitId: visitId,
  });

  const skinScannerStatus = useMemo(() => {
    return skinScannerStatusQuery.data?.status;
  }, [skinScannerStatusQuery.data?.status]);
  const statusLabel = skinScannerStatus
    ? statusLabels[skinScannerStatus]
    : "Could not get status";
  const canAssign = useMemo(() => {
    return (
      skinScannerStatus &&
      skinScannerStatus !== APITypesV1.SkinScannerStatus.OtherMemberAssigned
    );
  }, [skinScannerStatus]);

  const loading = assignSkinScannerMutation.isPending;
  const error = assignSkinScannerMutation.isError;
  const success = assignSkinScannerMutation.isSuccess;

  if (!patient) {
    return <BailView title="Could not fetch member" />;
  }

  return (
    <PageFrameContent>
      <section className={styles.base}>
        <section
          className={styles.transition}
          data-active={!loading && !error && !success}
          aria-hidden={loading || error || success}
        >
          <InformationLayout
            content={
              <section className={styles.layout}>
                <Typography variant="display-s">
                  Assign {patient.name?.displayName} to the skin scanner when
                  they are ready
                </Typography>
                <List>
                  <List.Item>
                    <div className={styles.item}>
                      <Typography variant="title-m">Skin scanner</Typography>
                      {statusLabel ? (
                        <StatusBadge error={!canAssign} label={statusLabel} />
                      ) : undefined}
                    </div>
                  </List.Item>
                </List>
              </section>
            }
            buttons={
              <ActionsFooter
                right={
                  <ActionButton
                    variant="suggestion"
                    disabled={!canAssign}
                    onClick={() => {
                      assignSkinScannerMutation.mutate(
                        {
                          patientId: patientId,
                          visitId: visitId,
                        },
                        {
                          onSuccess: () =>
                            setTimeout(
                              () =>
                                goTo(
                                  paths.checkInSuccess.url({
                                    patientId,
                                    visitId,
                                  })
                                ),
                              3000
                            ),
                        }
                      );
                    }}
                  >
                    Assign to skin scanner
                  </ActionButton>
                }
              />
            }
          />
          <Link
            className={styles.skipLink}
            onPress={() =>
              goTo(paths.checkInSuccess.url({ patientId, visitId }))
            }
          >
            Skip skin scan
          </Link>
        </section>
        <section
          className={styles.transition}
          data-active={loading || success}
          aria-hidden={!loading || !success}
        >
          <div className={styles.loader}>
            <SpinningLoader success={success} />
            <div className={styles.loaderText} data-active={success}>
              <Typography variant="title-s">Member assigned</Typography>
              <Typography variant="title-s">to skin scanner</Typography>
            </div>
            <div className={styles.checkmark} data-active={success}>
              <Checkmark />
            </div>
          </div>
        </section>
        <section
          className={styles.transition}
          data-active={error}
          data-bottom
          aria-hidden={!error}
        >
          <InformationLayout
            content={
              <section className={styles.title}>
                <Typography variant="display-s">
                  Couldn't assign member to the skin scanner
                </Typography>
                <Typography variant="body-m" color="subtle">
                  Please try again, or cancel and proceed with the other
                  examinations
                </Typography>
              </section>
            }
            buttons={
              <ActionsFooter
                left={
                  <ActionButton
                    variant="secondary"
                    direction="backward-centered"
                    onClick={() => {
                      goTo(paths.checkInSuccess.url({ patientId, visitId }));
                    }}
                    hideIcon
                  >
                    Cancel
                  </ActionButton>
                }
                right={
                  <ActionButton
                    variant="suggestion"
                    disabled={loading}
                    onClick={() => {
                      assignSkinScannerMutation.mutate(
                        {
                          patientId: patientId,
                          visitId: visitId,
                        },
                        {
                          onSuccess: () =>
                            setTimeout(
                              () =>
                                goTo(
                                  paths.checkInSuccess.url({
                                    patientId,
                                    visitId,
                                  })
                                ),
                              3000
                            ),
                        }
                      );
                    }}
                  >
                    Assign to skin scanner
                  </ActionButton>
                }
              />
            }
          />
        </section>
      </section>
    </PageFrameContent>
  );
}

type AssignToSkinScannerProps = {
  goTo(url: string): void;
} & CheckInParams;

const statusLabels = {
  [APITypesV1.SkinScannerStatus.CanAssign]: "Free",
  [APITypesV1.SkinScannerStatus.AssignedToMember]: "Already assigned",
  [APITypesV1.SkinScannerStatus.OtherMemberAssigned]: "Occupied",
} as const;

function StatusBadge({ error, label }: StatusBadgeProps) {
  return (
    <div className={styles.status} data-error={error}>
      <Typography variant="eyebrow-m" as="span">
        {label}
      </Typography>
    </div>
  );
}

type StatusBadgeProps = {
  error: boolean | undefined;
  label: string;
};
