import { codecs, createPath } from "@pomle/paths";

const root = createPath("/", {});

const locationSelector = root.append("/location-selector", {});

const selectVisit = root.append("/select-visit", {});

const selectActiveVisit = root.append("/select-active-visit", {});

const selectVisitConfirm = selectVisit.append("/confirm/:patientId/:visitId", {
  patientId: codecs.string,
  visitId: codecs.string,
});

const assignSlot = root.append("/assign-slot/:sessionId", {
  sessionId: codecs.string,
});

const checkIn = root.append("/check-in/:patientId/:visitId", {
  patientId: codecs.string,
  visitId: codecs.string,
});

const awaitingConsent = checkIn.append("/awaiting-consent", {});

const counterSign = checkIn.append("/counter-sign-consent", {});

const preferredName = checkIn.append("/preferred-name", {});

const immediateRiskAssessment = checkIn.append(
  "/immediate-risk-assessment",
  {}
);

const assignToRoom = checkIn.append("/assign-to-room", {});

const assignToSkinScanner = checkIn.append("/assign-to-skin-scanner", {});

const checkInSuccess = checkIn.append("/success", {});

const checkOut = checkIn.append("/checkout", {});

export const paths = {
  assignToRoom,
  assignToSkinScanner,
  checkInSuccess,
  root,
  awaitingConsent,
  counterSign,
  assignSlot,
  preferredName,
  immediateRiskAssessment,
  checkOut,
  locationSelector,
  selectVisit,
  selectActiveVisit,
  selectVisitConfirm,
  checkIn,
};
