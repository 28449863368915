import React from "react";
import { ReactComponent as NekoLogo } from "./assets/nekoLogo.svg";
import styles from "./styles.module.sass";
import { paths } from "render/routes/paths";

interface LogoHeaderProps {
  leftElement?: React.ReactNode;
  rightElement?: React.ReactNode;
}

export function LogoHeader({ leftElement, rightElement }: LogoHeaderProps) {
  return (
    <div className={styles.header}>
      <div className={styles.left}>{leftElement}</div>
      <a href={paths.root.url({})} className={styles.centre}>
        <NekoLogo />
      </a>
      <div className={styles.right}>{rightElement}</div>
    </div>
  );
}
