import { Visit } from "@cur8/rich-entity";
import { useMutation } from "@tanstack/react-query";
import { useAPIClient } from "render/context/APIContext";
import { useReporting } from "render/hooks/useReporting";

export function useStartCheckInMutation() {
  const apiClient = useAPIClient();
  const { logError } = useReporting();

  return useMutation({
    mutationFn({ patientId, visitId }: Pick<Visit, "visitId" | "patientId">) {
      return apiClient.visit.startCheckInProcess({ patientId, visitId }).result;
    },
    onError: (error) => logError(error),
  });
}
